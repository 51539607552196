<template>
  <div>
    <b-sidebar
      id="sidebar-filter"
      v-model="showSlide"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-filter-title"
      @hidden="hide()"
      no-header
    >
      <template>
        <div>
          <div class="p-2 d-flex justify-content-between align-items-center">
            <label class="header-text">กรองข้อมูล</label>
            <label class="pointer" @click="hide"
              ><b-icon icon="x-circle-fill"></b-icon
            ></label>
          </div>
          <div>
            <div class="p-2">
              <InputText
                textFloat="หมายเลขสมาชิก (Member ID)"
                v-model="filter.memberId"
                class="mb-2"
                placeholder="Member ID"
                type="text"
              />
              <InputText
                textFloat="ชื่อสมาชิก (Member Name)"
                v-model="filter.name"
                class="mb-2"
                placeholder="Name"
                type="text"
              />
              <InputText
                textFloat="หมายเลขโทรศัพท์ (Phone No.)"
                v-model="filter.telephone"
                class="mb-2"
                placeholder="Phone No."
                type="text"
              />
              <InputText
                textFloat="หมายเลขใบเสร็จ (POS Ref No.)"
                v-model="filter.invoiceNo"
                class="mb-2"
                placeholder="POS Ref No."
                type="text"
              />
              <div class="mb-2">
                <InputDatePickerFilter
                  textFloat="วันที่เริ่มต้น"
                  name="startDate"
                  ref="startDate"
                  placeholder="DD/MM/YYYY"
                  :max-datetime="filter.endDate || initFilterDate.MaxDate"
                  v-model="filter.startDate"
                  @input="changeStartDate"
                />
              </div>
              <div class="mb-2">
                <InputDatePickerFilter
                  textFloat="วันที่สิ้นสุด"
                  name="endDate"
                  ref="endDate"
                  placeholder="DD/MM/YYYY"
                  :min-datetime="filter.startDate"
                  :max-datetime="initFilterDate.MaxDate"
                  v-model="filter.endDate"
                  @input="onChangeEndDate"
                />
              </div>
              <InputText
                textFloat="รหัสพนักงาน (Saleperson ID)"
                v-model="filter.saleId"
                class="mb-2"
                placeholder="Saleperson ID"
                type="text"
              />
              <div>
                <div class="header-text">สถานะการซื้อ (Order status)</div>
                <b-form-select
                  class="input-container"
                  v-model="filter.status"
                  :options="transactionOption"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-center p-2">
          <p @click="clearFilter"><u>ล้างตัวกรอง</u></p>
          <b-button class="radius-btn bg-main mt-2" block @click="submitForm"
            ><span>ค้นหา</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";

export default {
  name: "SlideFilter",
  components: { InputText, InputDatePickerFilter },
  props: {
    filter: {
      type: Object,
      isRequired: true,
    },
  },
  data() {
    return {
      showSlide: false,
      transactionOption: [
        {
          text: "All",
          value: "0",
        },
        {
          text: "Completed",
          value: "1",
        },
        {
          text: "Void",
          value: "2",
        },
        {
          text: "Returned",
          value: "3",
        },
      ],
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
    };
  },
  created () {
    let toDay = this.$moment().format("YYYY-MM-DDT00:00:00");
    this.initFilterDate.MaxDate = toDay;
  },
  methods: {
    show() {
      this.showSlide = true;
    },
    hide() {
      this.showSlide = false;
    },
    changeStartDate() {
      if (!this.filter.endDate) this.filter.endDate = this.filter.startDate;
    },
    onChangeEndDate() {
      if (!this.filter.startDate) this.filter.startDate = this.filter.endDate;
    },
    async clearFilter() {
      this.filter.memberId = "";
      this.filter.name = "";
      this.filter.telephone = "";
      this.filter.invoiceNo = "";
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.saleId = "";
      this.filter.status = "0";
      this.filter.page = 1;
      this.filter.perPage = 8;
      this.$emit("onSubmitFilter");
    },
    async submitForm() {
      await this.$emit("onSubmitFilter");
      this.hide();
    },
  },
};
</script>
