<template>
  <div>
    <div class="py-2 text-general">
      <div class="p-2 d-flex justify-content-between">
        <label class="header-text text-main">ประวัติการสั่งซื้อ</label>
        <b-button class="d-flex align-items-center bg-main" @click="showFilter">
          <font-awesome-icon icon="filter" title="filter-btn" />
          <span class="mx-2 font-weight-bold d-md-down-none">กรองข้อมูล</span>
          <font-awesome-icon
            icon="chevron-circle-left"
            title="filter-btn"
            class="d-md-down-none"
          />
        </b-button>
      </div>
      <section class="d-md-none">
        <div class="bg-sub p-3">รายการสั่งซื้อ</div>
        <div v-if="!items.length" class="text-center p-3 border-bottom">
          ไม่มีข้อมูล
        </div>
        <div v-for="row of items" :key="row.id" class="p-3">
          <div class="row align-items-center">
            <div class="col-6 font-weight-bold">{{ row.invoice_no }}</div>
            <div class="col-6 text-right">{{ row.status }}</div>
            <div class="col-6 font-weight-bold">
              {{ row.customer_name }} ({{ row.member_id }})
            </div>
            <div class="col-6 text-right">{{ row.grand_total | numeral("0,0.00") }} บาท</div>
            <div class="col-6">{{ row.created_time | moment('DD MMM YYYY (HH:mm)') }}</div>
            <div class="col-6 text-right">{{ row.point }}</div>
            <div class="col-6">{{ row.branch_name }}</div>
            <div class="col-6 text-right">Sale ({{ row.sales_no }})</div>
          </div>
        </div>
        <b-pagination
          size="sm"
          limit="4"
          v-model="filter.page"
          :total-rows="rows"
          :per-page="filter.perPage"
          class="m-md-0 page-item mt-3"
          @change="pagination"
          align="center"
        ></b-pagination>
      </section>
      <div class="d-sm-down-none">
        <b-table
          responsive
          class="mt-2 text-center table-list mb-0 table-p-th"
          hover
          :tbody-tr-class="trTableReport"
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="ไม่มีรายการข้อมูลการซื้อ"
        >
          <template v-slot:head(invoice_no)="">
            <p class="mb-0">หมายเลขใบเสร็จ</p>
            <p class="mb-0">(POS Ref No.)</p>
          </template>
          <template v-slot:head(cancel_transaction_ref)="">
            <p class="mb-0">หมายเลขอ้างอิง</p>
            <p class="mb-0">(Cancel POS Ref No.)</p>
          </template>
          <template v-slot:head(customer_name)="">
            <p class="mb-0">ชื่อสมาชิก</p>
            <p class="mb-0">(Name)</p>
          </template>
          <template v-slot:head(telephone)="">
            <p class="mb-0">หมายเลขโทรศัพท์</p>
            <p class="mb-0">(Phone no.)</p>
          </template>
          <template v-slot:head(branch_name)="">
            <p class="mb-0">สาขา</p>
            <p class="mb-0">(Branch)</p>
          </template>
          <template v-slot:head(created_time)="">
            <p class="mb-0">วัน/เวลา</p>
            <p class="mb-0">(Date/Time)</p>
          </template>
          <template v-slot:head(status)="">
            <p class="mb-0">สถานะ</p>
            <p class="mb-0">(Status)</p>
          </template>
          <template v-slot:cell(invoice_no)="data">
            <div v-if="data.item.invoice_no">
              <label>{{ data.item.invoice_no }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(cancel_transaction_ref)="data">
            <div v-if="data.item.cancel_transaction_ref">
              <label>{{ data.item.cancel_transaction_ref }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(customer_name)="data">
            <div v-if="data.item.customer_name">
              <label>{{ data.item.customer_name }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(telephone)="data">
            <div v-if="data.item.telephone">
              <label>{{ data.item.telephone }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(branch_name)="data">
            <div v-if="data.item.branch_name">
              <label>{{ data.item.branch_name }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(created_time)="data">
            <div v-if="data.item.created_time">
              <label>{{
                new Date(data.item.created_time) | moment($formatDateTime)
              }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(status)="data">
            <div v-if="data.item.status">
              <label :class="data.item.status_id != 1 ? 'text-danger' : ''">{{
                data.item.status
              }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <div class="mt-2">
          <b-pagination
            size="sm"
            limit="4"
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.perPage"
            class="m-md-0 page-item"
            @change="pagination"
            align="center"
          ></b-pagination>
        </div>
      </div>
      <SlideFilter
        ref="SlideFilter"
        :filter="filter"
        @onSubmitFilter="getReportList"
      />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import SlideFilter from "./components/SlideFilter.vue";
import ModalLoading from "@/components/modal/alert/ModalLoading";
export default {
  name: "ReportIndex",
  components: {
    SlideFilter,
    ModalLoading,
  },
  data() {
    return {
      isBusy: false,
      filter: {
        memberId: "",
        name: "",
        telephone: "",
        invoiceNo: "",
        startDate: "",
        endDate: "",
        saleId: "",
        status: "0",
        page: 1,
        perPage: 8,
      },
      rows: 0,
      items: [],
      fields: [
        {
          key: "invoice_no",
          label: "หมายเลขใบเสร็จ",
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "cancel_transaction_ref",
          label: `หมายเลขอ้างอิง`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "customer_name",
          label: `ชื่อสมาชิก`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "telephone",
          label: `หมายเลขโทรศัพท์`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "branch_name",
          label: `สาขา`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "created_time",
          label: `วัน/เวลา`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "status",
          label: `สถานะ`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
      ],
      trTableReport: {
        "border-bottom": "1px solid #D9D9D9",
      },
    };
  },
  async created() {
    this.filter.memberId = this.$route.query.memberID || "";
    
    this.getReportList();
  },
  methods: {
    getReportList() {
      this.isBusy = true;
      this.axios
        .post(`${this.$baseUrl}/transaction/get_transactions`, this.setBody())
        .then((data) => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
          }
          this.isBusy = false;
        });
    },
    showFilter() {
      this.$refs.SlideFilter.show();
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getReportList();
    },
    setBody() {
      return {
        skip: (this.filter.page - 1) * this.filter.perPage,
        take: this.filter.perPage,
        branch_id: this.$cookies.get("branch_id"),
        name: this.filter.name,
        sales_person_id: this.filter.saleId,
        invoice_no: this.filter.invoiceNo,
        telephone: this.filter.telephone,
        from_date: this.filter.startDate || "1999-01-01T00:00:00",
        to_date: this.filter.endDate || this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        transaction_status: this.filter.status,
        member_id: this.filter.memberId,
        current_or_all: 1,
      };
    },
    LightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-slide-btn {
  background-color: #e8e9ec;
  border: none;
}
</style>
